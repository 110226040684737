@import './Nav/Nav.css';
@import './Theme.css';
@import './Home/Home.css';
@import './Projects/Projects.css';
@import './Experience/Experience.css';
@import './Skills/Skills.css';
@import './Contact/Contact.css';
@import './Blog/Index.css';
html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: var(--font);
    /* background-color: var(--soft); */
    
}
.app {
   margin-bottom: 10px; 
}
::-webkit-scrollbar {display:none;}

.d-paddng {
    background-color: var(--primary);
    padding-bottom: 10px;
    max-width: 830px;
    margin: 0 auto 10px;
}
.d-overview-container {
    background: linear-gradient(
        0.15turn, 
        rgb(0, 66, 104) 0%,
        rgb(1, 96, 151) 15%, 
        rgb(0, 112, 177) 32%, 
        rgb(0, 69, 133) 37%, 
        rgb(0, 118, 133) 95%);
        background-size: cover;
    background-repeat: no-repeat;
    max-width: 830px;
    margin: 0 auto 340px;
    padding-bottom: 5px;
    /* border-radius: 0 0 10px 10px; */
}
.m-portfolio-home-container {
    /* background-color: var(--primary); */
    width: 100%;
}
.t-overview-container {
    background-color: var(--primary);
    width: 100%;
}
.d-section-bottom {
    height: 5px;
    /* background-color: chartreuse; */
}
.d-section-container {
    /* width: 99.5%; */
    max-width: 820px;
    margin: 0 auto;
    /* padding: 10px 0; */
    /* min-height: 500px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(190, 234, 255);
    /* border-radius: 0 0 10px 10px; */

    /* background-color: rgb(255, 255, 255); */
    /* backgrounda-size: cover; */
    /* border-radius: 0 0 10px 10px; */
    /* background-color: var(--soft); */
}
.background-color-fix {
    max-width: 820px;
    background-color: rgb(190, 234, 255);
    margin: auto;

}
.m-section-container {
    background-color: rgb(220, 225, 233);
}
.t-section-container {
    min-height: 300px;
    background-color: white;
}

.d-section-fade {
    /* height: 5px; */
    /* width: 99.5%; */
    margin: 0 auto;
    /* background-image: 
        linear-gradient(
            to bottom,
            var(--soft),
            var(--soft),
            var(--primary),
            var(--soft),
            var(--soft)
        ); */
}
.m-section-fade {
    height: 50px;
    width: 100%;
    background-image: 
        linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.993),
            rgba(12, 95, 95, 0.9),
            rgba(255, 255, 255, 0.993)
        );
}
.t-section-fade {
    height: 40px;
    width: 100%;
    background-image: 
        linear-gradient(
            to bottom,
            rgba(18, 223, 212, 0.993),
            rgba(18, 223, 212, 0.7),
            rgba(18, 223, 212, 0.993)
        );
}
.m-scroll-fix {
    height: 40px;
    width: 1%;
    display: flex;
    flex-shrink: 0;
}
.m-scroll-fix-large {
    height: 100px;
    width: 1%;
    display: flex;
    flex-shrink: 0;
}
/* .myfont { */
    /* color: rgb(0, 69, 133); */
    /* letter-spacing: 0.5px;
    line-height: 1.25; */
/* } */
