.t-contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
}

.t-contact-header {
    font-size: 4rem;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;
}
.t-contact-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}
.t-contact-item {
    margin-bottom: 10px;
    /* width: 250px; */
    margin: 0 20px;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
}