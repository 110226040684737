.d-blog-container {
    /* width: 95%; */
    /* margin: auto; */
    /* min-height: 90vh; */
    /* background-color: white; */
    /* margin-bottom: 10px; */
    /* margin-top: 100px; */
    text-align: center;
    width: 400px;
    margin: auto;
    background-color: rgb(190, 234, 255);
}

